<template>
    <div>
        <MyAddress_Component></MyAddress_Component>
    </div>
</template>

<script>
import MyAddress_Component from '@/components/PersonalCenter/MyAddress_Component.vue'
export default {
    name: "MyAddresss",
    components: { MyAddress_Component }
}
</script>

<style lang="scss" scoped>

</style>