<template>
    <div class="myAddress">
        <div class="myAddress_Btn">
            <div class="input"><input v-model="serverValue" type="text" placeholder="请输入关键词搜索"></div>
            <div class="server" @click="handleServe"><i class="el-icon-search"></i>搜索</div>
            <div class="addAddress" @click="handleNewlyAdded">新增</div>
        </div>

        <!-- 新增 -->
        <div class="addressPu" v-show="addAddressS">

            <div class="addressBox">
                <div class="close" @click="addAddressS = false"><span>X</span></div>
                <div class="addressTitle">
                    <span>地址簿</span>
                </div>
                <!-- 输入框 -->
                <div class="addressInput">
                    <div class="addressInputLeft">
                        <!-- 姓名 -->
                        <div class="inputbox">
                            <p>公司</p>
                            <div class="nameInput inputs"><input v-model="corporateName" type="text"
                                    placeholder="请输入公司名称" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>联系电话</p>
                            <div class="nameInput inputs"><input v-model="telPhone" type="text" placeholder="请输入联系电话" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>邮箱</p>
                            <div class="nameInput inputs"><input v-model="mailboxs" type="text" placeholder="请输入邮箱" />
                            </div>
                        </div>
                    </div>
                    <div class="addressInputRight">
                        <div class="inputbox">
                            <p>姓名</p>
                            <div class="nameInput inputs"><input v-model="uName" type="text" placeholder="请输入姓名" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>详细地址</p>
                            <div class="nameInput inputs"><input v-model="address1" type="text"
                                    placeholder="请输入国家/州省/城市" /></div>
                        </div>
                        <!-- <div class="inputbox">
                            <p> &emsp;</p>
                            <div class="nameInput inputs"><input type="text" v-model="detailedAddress"
                                    placeholder="请填写详细地址（精确到门牌号）" /></div>
                        </div> -->
                        <div class="inputbox">
                            <p>邮政编码</p>
                            <div class="nameInput inputs"><input v-model="postalCode" type="text"
                                    placeholder="请填写邮政编码" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 智能地址填写 -->
                <!-- <div class="intelligence">
                    <p class="intelligenceTitle">智能地址填写</p>
                    <div class="intelligenceInput">
                        
                        <div class="textarea">
                            <textarea name="" v-model="intelligenceS" id="textarea" cols="30" rows="10"
                                placeholder="例如:张思, 13800138000,广东省广州市天河区某某大1001号"></textarea>

                        </div>

                        <div class="intelligenceBtn">
                            <div class="btn1">清除</div>
                            <div class="btn1">识别</div>
                        </div>
                    </div>
                </div> -->

                <div class="addressPuBtn" @click="confirms">确认</div>
            </div>
        </div>
        <!-- 修改 -->
        <div class="addressPu" v-show="modifyAddressS">
            <div class="addressBox">
                <div class="close" @click="modifyAddressS = false"><span>X</span></div>
                <div class="addressTitle">
                    <span>地址簿</span>
                </div>
                <!-- 输入框 -->
                <div class="addressInput">
                    <div class="addressInputLeft">
                        <div class="inputbox">
                            <p>公司名称</p>
                            <div class="nameInput inputs"><input v-model="corporateName" type="text"
                                    placeholder="请输入公司名称" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>手机/固话</p>
                            <div class="nameInput inputs"><input v-model="telPhone" type="text" placeholder="请填写联系方式" />
                            </div>
                        </div>

                        <div class="inputbox">
                            <p>邮箱</p>
                            <div class="nameInput inputs"><input v-model="mailboxs" type="text" placeholder="请输入邮箱" />
                            </div>
                        </div>
                    </div>
                    <div class="addressInputRight">
                        <!-- 姓名 -->
                        <div class="inputbox">
                            <p>姓名</p>
                            <div class="nameInput inputs"><input v-model="uName" type="text" placeholder="请输入姓名" />
                            </div>
                        </div>
                        <div class="inputbox">
                            <p>详细地址</p>
                            <div class="nameInput inputs"><input v-model="address1" type="text"
                                    placeholder="请选择国家/州省/城市" /></div>
                        </div>
                        <!-- <div class="inputbox">
                            <p> &emsp;</p>
                            <div class="nameInput inputs"><input type="text" v-model="detailedAddress"
                                    placeholder="请填写详细地址（精确到门牌号）" /></div>
                        </div> -->
                        <div class="inputbox">
                            <p>邮政编码</p>
                            <div class="nameInput inputs"><input v-model="postalCode" type="text"
                                    placeholder="请填写邮政编码" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 智能地址填写 -->
                <!-- <div class="intelligence">
                    <p class="intelligenceTitle">智能地址填写</p>
                    <div class="intelligenceInput">
                        <div class="textarea">
                            <textarea name="" v-model="intelligenceS" id="textarea" cols="30" rows="10"
                                placeholder="例如:张思, 13800138000,广东省广州市天河区某某大1001号"></textarea>

                        </div>

                        <div class="intelligenceBtn">
                            <div class="btn1">清除</div>
                            <div class="btn1">识别</div>
                        </div>
                    </div>
                </div> -->

                <div class="addressPuBtn" @click="modifyConfirms">确认</div>
            </div>
        </div>
        <div class="tabs" v-if="isReloadData">
            <el-table :data="addressList" style="width: 100%" border :header-row-style="{ height: '50px' }"
                :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '56px' }">
                <el-table-column fixed prop="contact" label="公司名称" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="scopes">
                            <div>

                                <span>{{ scope.row.company }}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed prop="contact" label="姓名" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed prop="tel" label="联系电话" width="150" show-overflow-tooltip>
                </el-table-column>

                <el-table-column fixed prop="addr" label="详细地址" width="300" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed prop="email" label="邮箱" min-width="150" show-overflow-tooltip>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="240" class-name="nana" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="modifyRow(scope.$index, scope.row)" type="text" size="small">
                            修改
                        </el-button>
                        <el-button @click.native.prevent="deleteRow(scope.$index, scope.row)" type="text" size="small">
                            移除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';

export default {
    data() {
        return {
            radios: false,
            serverValue: "", //搜索
            addAddressS: false, //新增
            modifyAddressS: false, //修改
            intelligenceS: "", //智能输入
            addressList: [],
            corporateName: "", //公司名称
            uName: "", //姓名
            address1: "",//地址
            // detailedAddress: "",//详细地址
            mailboxs: "",//邮箱
            postalCode: "",//邮政编码
            telPhone: "",//联系方式
            rowId: "", //修改ID

            isReloadData: true,

            formData: {
                pageNo: 1, //当前页
            },
            paging: {
                total: 0,
                size: 5,
            },

        }
    },
    created() {
        this.getaddressList()
    },
    methods: {
        getaddressList() {
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                params: { pageNo: "" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result);
                    this.addressList = res.data.result.records;
                    this.paging.total = res.data.result.total;
                    this.paging.size = res.data.result.size;
                })
                .catch(err => {
                    console.error(err);
                })
        },


        reloadPart() {
            this.isReloadData = false;
            this.$nextTick(() => {
                this.isReloadData = true;
                // console.log("%%");
            })
        },


        handleServe() {  //搜索
            var token = sessionStorage.getItem("token")
            // console.log(this.serverValue);
            axios.get(`${baseUrl}/member/addressList`, {
                //参数列表
                params: { keyWords: this.serverValue },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    // console.log(res)
                    this.addressList = res.data.result.records;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleNewlyAdded() {
            // console.log("%");
            this.addAddressS = true;
        },
        confirms() {  //点击确认 添加

            var token = sessionStorage.getItem("token")
            if (this.corporateName == '') {
                this.$message({
                    showClose: true,
                    message: '请输入公司名称',
                    type: 'warning'
                });
                return false
            } else if (this.uName == "") {
                this.$message({
                    showClose: true,
                    message: '请输入姓名',
                    type: 'warning'
                });
                return false
            } else if (this.telPhone == "") {
                this.$message({
                    showClose: true,
                    message: '请输入联系方式',
                    type: 'warning'
                });
                return false
            } else if (this.address1 == "") {
                this.$message({
                    showClose: true,
                    message: '请输入详细地址',
                    type: 'warning'
                });
                return false
            }
            else if (this.mailboxs == "") {
                this.$message({
                    showClose: true,
                    message: '请输入邮箱',
                    type: 'warning'
                });
                return false
            }
            else {
                axios.post(`${baseUrl}/member/addressAdd`, {
                    company: this.corporateName,
                    contact: this.uName,
                    tel: this.telPhone,
                    email: this.mailboxs,
                    addr: this.address1,
                    postCode: this.postalCode,
                }, {
                    headers: {
                        'authorization': token
                    }
                })
                    .then(res => {
                        // console.log(res);
                        if (res.data.success == true) {
                            this.$message({
                                showClose: true,
                                message: '添加成功',
                                type: 'success'
                            });
                            this.addAddressS = false;
                            this.corporateName = this.uName = this.telPhone = this.address1 = this.mailboxs = this.postalCode = ''
                            this.getaddressList()
                        } else {
                            let message = res.data.message;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'error'
                            });
                        }

                        // console.log(res)
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }

        },
        modifyRow(index, row) {  //修改地址
            console.log(row);
            this.corporateName = row.company
            this.modifyAddressS = true;
            this.rowId = row.id;
            // console.log(row);
            this.uName = row.contact; //姓名
            this.address1 = row.addr;//地址
            this.mailboxs = row.email;//邮箱
            this.postalCode = row.postCode;//邮政编码
            this.telPhone = row.tel;//联系方式

        },
        deleteRow(index, row) {
            var token = sessionStorage.getItem("token")
            // console.log(row.id);
            axios.delete(`${baseUrl}/member/addressDelete`, {
                params: {
                    id: row.id,
                },
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    // console.log(res)
                    if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getaddressList()
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                        return false
                    }

                })
                .catch(err => {
                    console.error(err);
                })
        },
        modifyConfirms() {  //确认修改按钮
            var token = sessionStorage.getItem("token");
            if (this.corporateName == '') {
                this.$message({
                    showClose: true,
                    message: '请输入公司名称',
                    type: 'warning'
                });
                return false
            } else if (this.uName == "") {
                this.$message({
                    showClose: true,
                    message: '请输入姓名',
                    type: 'warning'
                });
                return false
            } else if (this.telPhone == "") {
                this.$message({
                    showClose: true,
                    message: '请输入联系方式',
                    type: 'warning'
                });
                return false
            } else if (this.address1 == "") {
                this.$message({
                    showClose: true,
                    message: '请输入详细地址',
                    type: 'warning'
                });
                return false
            } else if (this.mailboxs == "") {
                this.$message({
                    showClose: true,
                    message: '请输入邮箱',
                    type: 'warning'
                });
                return false
            } else {
                axios.post(`${baseUrl}/member/addressEdit`, {
                    company: this.corporateName,
                    contact: this.uName,
                    tel: this.telPhone,
                    email: this.mailboxs,
                    addr: this.address1,
                    postCode: this.postalCode,
                    id: this.rowId,
                }, {
                    headers: {
                        'authorization': token
                    }
                })
                    .then(res => {
                        // console.log(res);
                        if (res.data.success == true) {
                            this.$message({
                                showClose: true,
                                message: '修改成功',
                                type: 'success'
                            });
                            this.modifyAddressS = false;
                            this.corporateName = this.uName = this.telPhone = this.address1 = this.mailboxs = this.postalCode = ''
                            this.getaddressList()
                        } else {
                            let message = res.data.message;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'error'
                            });
                            return false
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },

        handleSizeChange(val) { //分页
            var token = sessionStorage.getItem("token");
            axios.get(`${baseUrl}/member/addressList`, {
                params: { pageNo: val },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    // console.log()
                    if (res.data.success == true) {
                        this.addressList = res.data.result.records;
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }
    },


    updated() {
        // console.log(this.intelligenceS);
    },
}
</script>

<style lang="scss" scoped>
.myAddress {

    margin: 0 auto;
    // max-width: 1526px;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    padding-left: 32px;

    .myAddress_Btn {
        display: flex;
        align-items: center;

        .input {
            width: 350px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;

            input {
                width: 100%;
                height: 100%;
                padding-left: 16px;
                border-radius: 4px 4px 4px 4px;
                color: #333333;
                border: 1px solid #C0C4CC;
                cursor: pointer;
            }

            input:focus {
                outline: 1px solid #409EFF;
            }
        }

        .server {
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #2B2B2B;
            margin-left: 24px;
            cursor: pointer;
        }

        .addAddress {
            width: 120px;
            height: 40px;
            background: #4CAF50;
            box-shadow: 0px 3px 6px 1px rgba(76, 175, 80, 0.3);
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
            margin-left: 24px;
            cursor: pointer;
        }
    }

    .addressPu {
        position: absolute;
        // position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(97, 97, 97, 0.3);
        z-index: 10;



        .addressBox {
            position: absolute;
            left: 50%;
            top: 172px;
            transform: translate(-50%);
            width: 800px;
            height: 566px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
            padding-bottom: 32px;

            .close {
                position: absolute;
                top: 20px;
                right: 20px;
                color: #FFFFFF;
                cursor: pointer;
            }

            .addressTitle {
                width: 800px;
                height: 64px;
                background: #1890FF;
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 64px;
            }

            .addressInput {
                padding: 32px 32px 0;
                display: flex;
                justify-content: space-between;
                height: 260px;

                .addressInputLeft,
                .addressInputRight {
                    width: 320px;

                    .inputbox {
                        margin-top: 20px;

                        p {
                            font-size: 14px;
                            font-weight: 700;
                            color: #333333;
                        }

                        .inputs {
                            width: 320px;
                            height: 25px;
                            // border-bottom: 1px solid;

                            margin-top: 17px;

                            input {
                                width: 100%;
                                height: 100%;
                                outline: none;
                                border: 0;
                                border-bottom: 1px solid #999999;
                                padding-bottom: 8px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #999999;
                            }

                            input:focus {
                                border-bottom: 1px solid #1890FF;
                            }
                        }
                    }

                    .inputbox:nth-child(1) {
                        margin-top: 0;
                    }
                }

                .addressInputRight {
                    width: 320px;
                }
            }

            .intelligence {
                padding: 0 32px;
                margin-top: 30px;

                .intelligenceTitle {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                }

                .intelligenceInput {
                    position: relative;
                    display: flex;
                    width: 736px;
                    height: 90px;
                    background: #F9F9F9;
                    margin-top: 8px;

                    // input {
                    //     border: 0;
                    // }
                    .textarea {
                        width: 580px;
                    }

                    #textarea {
                        padding: 16px 0 0 16px;
                        width: 100%;
                        height: 90px;
                        border: none;
                        outline: none;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999999;
                        resize: none;
                        background-color: #F9F9F9;
                    }

                    .intelligenceBtn {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        display: flex;

                        .btn1 {
                            width: 70px;
                            height: 30px;
                            background: #999999;
                            border-radius: 2px 2px 2px 2px;
                            text-align: center;
                            line-height: 30px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #FFFFFF;
                            cursor: pointer;
                        }

                        .btn1:nth-child(2) {
                            margin-left: 14px;
                            background: #D4252C;
                        }
                    }


                }

            }

            .addressPuBtn {
                width: 100px;
                height: 34px;
                background: #D4252C;
                border-radius: 30px 30px 30px 30px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 34px;
                margin: 41px auto 0;
            }
        }
    }


    .tabs {
        margin-top: 59px;
        padding-right: 24px;
        padding-bottom: 32px;

        ::v-deep .nana {
            text-align: center;
        }

        ::v-deep .el-table_1_column_2 {
            text-align: center;
        }

        .el-button {
            padding: 6px 12px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        }

        .scopes {
            display: flex;
            align-items: center;

            .radio {
                margin-right: 18px;
            }
        }

        .paging {
            display: flex;
            justify-content: flex-end;
            position: relative;
            // float: right;
            margin-top: 32px;
            // background-color: #FFFFFF;
        }

    }
}
</style>